import "./bootstrap";

import "quasar/src/css/index.sass";
import "quasar/src/css/flex-addon.sass";
import "@/../css/app.scss";
// ordering of style imports above does matter!!

import { createApp, h } from "vue";
import { createInertiaApp } from "@inertiajs/vue3";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import { ZiggyVue } from "../../vendor/tightenco/ziggy/dist/vue.m";
import { Quasar } from "quasar";
import { i18nVue } from "laravel-vue-i18n";
import * as Sentry from "@sentry/vue";

const appName = import.meta.env.VITE_APP_NAME || "Laravel";

createInertiaApp({
  title: (title) => `${title} - ${appName}`,
  resolve: (name) =>
    resolvePageComponent(
      `./Pages/${name}.vue`,
      import.meta.glob("./Pages/**/*.vue"),
    ),
  setup({ el, App, props, plugin }) {
    const app = createApp({ render: () => h(App, props) });

    Sentry.init({
      app,
      dsn: "https://e030ca9c0ba7f451a5452af5bf56a6a9@o4508088188010496.ingest.de.sentry.io/4508088194236496",
      integrations: [
        Sentry.replayIntegration(),
      ],
      environment: import.meta.env.VITE_APP_ENV,
      // Tracing
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });

    app
      .use(plugin)
      .use(ZiggyVue)
      .use(Quasar)
      .use(i18nVue, {
        resolve: async (lang) => {
          const langs = import.meta.glob("../../lang/*.json");
          return await langs[`../../lang/${lang}.json`]();
        },
      })
      .mount(el);
    
    return app;
  },
  progress: {
    color: "#4B5563",
  },
});
