/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

import axios from 'axios';
window.axios = axios;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

import Echo from 'laravel-echo';

import Pusher from 'pusher-js';
window.Pusher = Pusher;

window.Echo = new Echo({
  broadcaster: 'pusher',
  key: import.meta.env.VITE_PUSHER_APP_KEY,
  cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
  wsHost: window.location.hostname,
  wsPort: import.meta.env.VITE_PUSHER_PORT,
  wssPort: import.meta.env.VITE_PUSHER_PORT,
  forceTLS: (import.meta.env.VITE_PUSHER_SCHEME ?? 'https') === 'https',
  encrypted: true,
  disableStats: true,
  enabledTransports: ['ws', 'wss'],
});

// necessary for downloading models
window.roqedFetchOverrideURLs = window.roqedFetchOverrideURLs ?? []
const oldFetch = window.fetch
window.fetch = async function () {
  const response = await oldFetch.apply(this, arguments)
  const targetURLs = window.roqedFetchOverrideURLs ?? []

  const methodIsGet = arguments[0]?.method === 'GET'
  const urlIsWhitelisted = targetURLs.includes(arguments[0]?.url)
  const loadedDataIsFile = response.headers.get("content-type") === 'application/octet-stream'

  if (!(methodIsGet && urlIsWhitelisted && loadedDataIsFile)) {
    return response
  }

  const reader = response.body.getReader()
  const csrfKey = document.querySelector('meta[name="csrf-token"]').content
  const byteKey = Uint8Array.from(Array.from(csrfKey).map(letter => letter.charCodeAt(0)))

  let totalIdx = 0

  const stream = new ReadableStream({
    type: 'bytes',
    start(controller) {
      async function push() {
        const {done, value} = await reader.read()
        if (done) {
          controller.close()
          return
        }

        // xor buffer with our key
        for (let bufferIdx = 0; bufferIdx < value.length; bufferIdx++, totalIdx++) {
          value[bufferIdx] = value[bufferIdx] ^ byteKey[totalIdx % byteKey.length]
        }

        // Enqueue the next data chunk into our target stream
        controller.enqueue(value)
        push()
      }

      push()
    }
  })

  return new Response(stream)
}
